<template>
  <div class="rigister-page"
       :class=' {noScoll: (termsUsePage || privacyAgreementPage)? true : false   }'
  >
    <div class="page-top">
      <div class="flex top-logo">
        <div class="box-img">
          <img src="../../assets/images/box/logo.png" alt="" />
        </div>
        <text class="flex">让人际关系更简单 !</text>
      </div>
    </div>
    <div class="page-center">
      <div class="login-form center">
        <div class="form-header">
<!--          <div class="person-avatar">-->
<!--            <a-avatar-->
<!--              :src="invitePersonInfo.avatar || defalutAvatar"-->
<!--              :size="52"-->
<!--              v-if="invitePersonInfo.avatar"-->
<!--            />-->
<!--          </div>-->
          <div class="title">欢迎您加入人脉宝盒</div>
        </div>
        <a-form
          ref="newAccountForm"
          :model="form.newAccount"
          :wrapper-col="{ span: 24 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item v-bind="validateInfosNewAccount.validateInfos.userName">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="form.newAccount.userName"
              placeholder="昵称"
            />
          </a-form-item>
          <a-form-item v-bind="validateInfosNewAccount.validateInfos.mobile">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="form.newAccount.mobile"
              :placeholder="$t('login.account.placeholder')"
            />
          </a-form-item>
          <a-form-item
            v-bind="validateInfosNewAccount.validateInfos.verifyCode"
          >
            <a-input
              autocomplete="off"
              v-model:value="form.newAccount.verifyCode"
              :placeholder="$t('login.code')"
            />
            <!--       获取验证码按钮    -->
            <a-button
              class="code-img"
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode('newAccount')"
            >
              {{ btn.title }}
            </a-button>
          </a-form-item>
          <a-form-item v-bind="validateInfosNewAccount.validateInfos.password">
            <a-input-password
              v-model:value="form.newAccount.password"
              placeholder="请输入8-20位新密码"
              @keyup.enter="handleLogin"
              autocomplete="new-password"
            />
          </a-form-item>
          <!--      邀请码   -->
<!--          v-bind="validateInfosNewAccount.validateInfos.inviterCode" -->
          <a-form-item

          >
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="form.newAccount.inviterCode"
              placeholder="请输入邀请码"
            />
          </a-form-item>
        </a-form>

        <!--<template v-else>
          <div class="success-center">
            <div class="join-success flex">
              <div class="invite-success">
                <img src="../../assets/images/invite-success.png" alt="" />
              </div>

              <text class="title">已加入人脉宝盒</text>
            </div>
            <div class="download-tip">
              您已加入人脉宝盒您可使用+{{ userPhone }} 登录人脉宝盒
            </div>
          </div>
        </template> -->
      </div>
    </div>
    <div class="bottom-btn">
      <div class="agreement agreementActivity" v-if="!success">
        <a-checkbox v-model:checked="checked"> </a-checkbox>
        <div class="ml-8">
          已阅读并同意<a  @click='openUserAgreementPage'>《使用协议》</a>和
          <a  @click='openPrivacyAgreementPage'>《隐私保护协议》</a>
        </div>
      </div>
      <a-button class="btn" block @click="handleLogin()"> 注册 </a-button>
      <a-button class="btn" block @click="downloadLine()"> 下载 </a-button>
<!--      <a href="taobao://">淘宝</a>-->
<!--      <br>-->
<!--      <a href="weixin://">微信</a>-->
      <div style="text-align: center; color: #bfbfbf">
        已经有人脉宝盒？
        <text style="color: #ff7b00" @click="openApp">打开</text>
<!--        <br>-->
<!--        <a href="hbuilder://">打开uni App</a>-->
      </div>
    </div>
  </div>
<!--  <line-modal-->
<!--    v-model:visible="lineModalPage"-->
<!--    :init='downloadUrl'-->
<!--  />-->
  <privacy-agreement
    v-model:visible="privacyAgreementPage"
  />
  <terms-use
    v-model:visible="termsUsePage"
  />
</template>

<script>
import { ref, onMounted, reactive, toRefs, toRaw, computed } from 'vue';

import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import loginApi from '@/api/login.js';
import { randoms } from '@/utils';
import { Checkbox,
  // Avatar,
  Form, message } from 'ant-design-vue';
import { validMobile, validEmail } from '@/utils/validation';
import defalutAvatar from '../../assets/images/default-logo.png';
import privacyAgreement from '@/views/mRigister/components/privacyAgreementM';
import termsUse from '@/views/mRigister/components/termsUseM';
import todayApi from '@/api/today';
// import lineModal from '@/views/mRigister/components/lineModal';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    // AAvatar: Avatar,
    ACheckbox: Checkbox,
    privacyAgreement,
    termsUse,
    // lineModal,
  },
  setup() {
    const newAccountForm = ref();
    const store = useStore();
    const router = useRouter();
    const passwordForm = ref();
    const route = useRoute();
    const required = { required: true, whitespace: true, message: '不能为空' };
    const state = reactive({
      downloadUrl: '',
      privacyAgreementPage: false,
      termsUsePage: false,
      lineModalPage: false,
      spinning: false,
      // success: false,
      loading: false,
      //  loginText: '加入',
      clearTimer: '',
      checked: false,
      // invitePersonInfo: {
      //   userName: '',
      //   avatar: '',
      // },
      // 密码校验
      passwordForm: { password: '' },
      passwordRules: {
        password: [
          required,
          {
            pattern:
              /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
            message: '请输入正确的密码格式',
          },
        ],
      },
      btn: {
        disabled: false,
        title: '获取验证码',
      },

      timer: null
    });
    const form = reactive({
      //  注册账号
      newAccount: {
        userName: '',
        mobile: '',
        verifyCode: '',
        password: '',
        areaCode: '86',
        inviterCode: route.query.inviterCode ? route.query.inviterCode : '',
        channelSource: '官网',
      },
    });
    const validateInfosNewAccount = Form.useForm(form.newAccount, {
      verifyCode: [required],
      mobile: [
        {
          validator: async (rule, mobile) => {
            if (mobile) {
              if (validMobile(mobile) || validEmail(mobile)) {
                return Promise.resolve();
              }
              // 或者邮箱
              return Promise.reject('请输入合法的手机号');
            }
            return Promise.reject('不能为空');
          },
        },
      ],
      password: [
        required,
        {
          pattern: /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
          message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
        },
      ],
      userName: [required],
      // inviterCode: [required],
    });
    const getCaptcha = () => {
      state.spinning = true;
      form.loginForm.key = randoms(24, 16);
      loginApi
        .getCaptcha(form.loginForm.key)
        .then(data => (state.codeSrc = data))
        .catch(err => {
          console.log(err);
          getCaptcha();
        })
        .finally(() => (state.spinning = false));
    };
    // const getInviterInfoFun = () => {
    //   let data = {
    //     code: route.query.inviterCode,
    //   };
    //   loginApi.getInviterInfo(data).then(res => {
    //     if (res) {
    //       state.invitePersonInfo = res;
    //
    //     }
    //   });
    // };

    // 获取最新app版本信息
    const getLatestVersionInfo = () => {
      todayApi
        .getLatestVersionInfo('', {})
        .then(res => {
          if (res) {
            // 获取浏览器信息
            let u = navigator.userAgent;
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

            if (isIOS) {
              // 获取 User Agent
              let userAgent = u.toLowerCase();
              // 判断是否在微信中打开
              if (userAgent.indexOf('micromessenger') !== -1) {
                // console.log('当前页面在微信中打开');
                return message.info('请跳转到浏览器打开链接')
              } else {
                // console.log('当前页面不在微信中打开');
                // const appleID = 'com.renmaibaohe';
                window.location.href = `https://apps.apple.com/cn/app/id6587572958`
              }

              // return message.info('APP暂不支持IOS，敬请期待。')
            } else  {
              state.downloadUrl = res.downloadUrl
              window.location.href = state.downloadUrl
              // window.open(state.downloadUrl)
              clearTimeout( state.timer)

            }

          }
        });
    };

    onMounted(() => {
      // getLatestVersionInfo();

    });
    const downloadLine = ()=> {
      // 获取浏览器信息
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // alert('isIOS:'+`${isIOS}`)
      if (isIOS) {
        // 获取 User Agent
        let userAgent = u.toLowerCase();
        // 判断是否在微信中打开
        if (userAgent.indexOf('micromessenger') !== -1) {
          // console.log('当前页面在微信中打开');
          return message.info('请跳转到浏览器打开链接')
        } else {
          // console.log('当前页面不在微信中打开');
          // const appleID = 'com.renmaibaohe';
          window.location.href = `https://apps.apple.com/cn/app/id6587572958`
        }
        // return message.info('APP暂不支持IOS，敬请期待。')
      } else  {
        // alert("none isIOS，none isIOS，none isIOS"+`464${isIOS}`)
        todayApi
          .getLatestVersionInfo('', {})
          .then(res => {
            if (res) {
              state.downloadUrl = res.downloadUrl
              // window.open(state.downloadUrl)
              window.location.href = state.downloadUrl
            }
          });
      }

    };
    
    const openApp = () => {
      // 获取浏览器信息
      let u = navigator.userAgent.toLowerCase();
      // console.log('uuu', u)
      // 判断浏览器是否为头部浏览器 qq 微博 微信开发者工具
      let isHeaderAgent =
        /micromessenger/i.test(u) ||
        u.indexOf('weibo') > -1 ||
        u.indexOf('qq') > -1 ||
        u.indexOf('mqqbrowser') > -1;

      if (isHeaderAgent) {
        // 打开apk连接
        getLatestVersionInfo()
        // state.lineModalPage = true
        // alert("您这是内置浏览器,请更换浏览器打开")
        // message.error('请去浏览器打开链接');

      } else {
        // getLatestVersionInfo()
        try {
          let ur = navigator.userAgent;
          let isAndroid = ur.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
          let isIOS = !!ur.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          // var t = 'connectionstreasureboxapp://pages/today/index';
          // var downLoadUrl = state.downloadUrl;
          console.log(isAndroid)
          // 打开apk连接
          if (isIOS) {

            // 获取 User Agent
            let userAgent = u.toLowerCase();
           // 判断是否在微信中打开
            if (userAgent.indexOf('micromessenger') !== -1) {
              // console.log('当前页面在微信中打开');
              return message.info('请跳转到浏览器打开链接')
            } else {
              // console.log('当前页面不在微信中打开');
              // const appleID = 'com.renmaibaohe';
              window.location.href = `https://apps.apple.com/cn/app/id6587572958`
            }

            // return message.info('APP暂不支持IOS，敬请期待。')
          } else {
            window.location.href = 'connectionstreasureboxapp://pages/today/index';
            state.timer = setTimeout(function () {
              // alert("定时器")
              message.info('未识别到人脉宝盒APP，正在跳转下载...')
              getLatestVersionInfo()

            }, 2000);
          }

          // 查看安卓配置
          // if (isAndroid) {
          //   t = 'connectionstreasureboxapp://pages/today/index';
          //
          // } else if (isIOS) {
          //   t = 'connectionstreasureboxapp://pages/today/index';
          // }


          // }
        } catch (e) {
          // alert("error,error")
          // window.location.href = 'connectionstreasureboxapp://pages/today/index';
          getLatestVersionInfo()
        }

      }
    };

    onMounted(() => {
      // getInviterInfoFun();
    });
    return {
      ...toRefs(state),
      form,
      getCaptcha,
      passwordForm,
      newAccountForm,
      required,
      validateInfosNewAccount,
      defalutAvatar,
      openApp,
      downloadLine,
      handleGetCode() {
        state.validataCode = validateInfosNewAccount;
        state.codeParmas = form.newAccount.mobile;
        state.validataCode?.validate('mobile').then(() => {
          loginApi.getCode(state.codeParmas).then(res => {
            message.success('验证码已发送，请注意查收');
            if (res) {
              // store.commit('account/setTenant', data);
              state.btn = { disabled: true, title: '重新获取(60s)' };
              let count = 60;
              state.clearTimer = setInterval(() => {
                if (count === 1) {
                  state.btn = { disabled: false, title: '获取验证码' };
                  clearInterval(state.clearTimer);
                  state.clearTimer = null;
                } else {
                  count--;
                  state.btn.title = `重新获取(${count}s)`;
                }
              }, 1000);
            } else {
              message.error('该账号不存在企业信息，请重新输入');
            }
          });
        });
      },
      // todo 注册方法
      handleLogin() {
        validateInfosNewAccount.validate().then(() => {
          if (!state.checked) {
            return message.error('请勾选协议！');
          }
          state.loading = true;
          loginApi
            .addSysUser('', toRaw(form.newAccount))
            .then(res => {
              if (res) {
                message.success('注册成功');
                validateInfosNewAccount.resetFields();
                state.checked = false;
                clearInterval(state.clearTimer);
                state.clearTimer = null;
              }
              // router.push('/');
            })
            .catch(() => {
              form.loginForm.verifyCode = '';
              // getCaptcha();
            })
            .finally(() => {
              setTimeout(() => {
                state.loading = false;
              }, 1500);
            });
        });
      },
      // userPhone: computed(() =>
      //   form.newAccount.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
      // ),
      openPrivacyAgreementPage() {
        state.privacyAgreementPage = true
      },
      openUserAgreementPage() {
        console.log('openUserAgreementPage')
        state.termsUsePage = true
      },

    };
  },
};
</script>
<style scoped lang="less">
.rigister-page {
  height: 100vh;
  position: relative;
  background-color: #fafaf9;
  .page-top {
    box-sizing: border-box;
    padding: 3%;
    height: 10vh;
    .top-logo {
      justify-content: flex-start;
      width: 100%;

      .box-img {
        flex: 0 0 25%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      text {
        flex: 1;
        color: #bfbfbf;
        letter-spacing: 2px;
        justify-content: flex-start;

        &::before {
          content: '';
          display: inline-block;
          margin: 0 3%;
          width: 1px;
          height: 10px;
          background-color: #333;
        }
      }
    }
  }
  .page-center {
    padding: 2% 2% 0 2%;
    border-radius: 10px;
    background-color: #fff;
  }
  .center {
    width: 100%;
    //padding: 0 10% 20% 10%;
    padding: 0 10% 6% 10%;
    margin: 0 auto;
    border-radius: 10px;
    min-height: calc(100vh - 55vh);
    background: linear-gradient(
      -45deg,
      #fafaf9 0%,
      #fafaf9 10%,
      white 65%,
      #fcdbc6 100%
    );
    .form-header {
      position: relative;
      padding: 15% 0 10% 0;
      margin: 0 auto;
      text-align: center;

      .person-avatar {
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translate(-50%, 60%);
      }
    }
  }

  .login-form {
    box-sizing: border-box;
    :deep(.ant-form label) {
      font-size: 16px;
    }
    .code-contain {
      width: 192px;
      height: 192px;
      margin: 66px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background: url('../../assets/images/login/code-contain-back.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;

      .expire-mark {
        width: 180px;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        gap: 22px;

        .expire-tip {
          color: #ffffff;
          font-size: 18px;
          font-weight: 400;
        }

        .expire-btn {
          padding: 8px 32px;
          box-sizing: border-box;
          border: 1px solid #ffffff;
          border-radius: 5px;
          cursor: pointer;

          span {
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      #codeArea {
        width: 160px;
        height: 160px;
      }
    }

    :deep(.ant-form-item) {
      //margin-bottom: 16px;
      .anticon-eye,
      .anticon-eye-invisible {
        color: rgba(0, 0, 0, 0.25);
      }

      &.ant-form-item-with-help {
        margin-bottom: 0;
      }
    }

    :deep(.ant-form-item-label) {
      text-align-last: justify;
      text-align: justify;
      text-justify: distribute-all-lines; // 兼容 IE
    }

    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper),
    :deep(.ant-input-affix-wrapper-focused) {
      line-height: 30px;
      border-radius: 4px;
      //border-width: 0px 0px 1px 0px !important;
      box-shadow: none;
      background-color: #fff;

      &:hover,
      &:focus {
        //border-right-width: 0px !important;
        box-shadow: none;
      }
    }

    .join-success {
      margin: 0 auto;
      padding: 15% 0 10% 0;
      text-align: center;
      justify-content: center;
      .invite-success {
        width: 35px;
        margin-right: 5%;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .download-tip {
      margin-top: 10%;
      text-align: left;
      width: 63%;
      margin: 0 auto;
    }
    .code-img {
      position: absolute;
      right: 0;
      top: 4px;
      right: 4px;
      border: none;
      box-shadow: none !important;
      cursor: pointer;

      :deep(.ant-image-img) {
        width: auto;
        height: 100%;
      }
    }

    :deep(.ant-input-clear-icon) {
      margin: 0;
    }
  }

  .agreement {
    color: #999999;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  .bottom-btn {
    padding: 0 10% 30% 10%;
    box-sizing: border-box;
  }
  .btn {
    margin: 10px 0 10px 0;
    height: 40px;
    font-size: 16px;
    background: linear-gradient(0deg, #ff7b00 0%, #ffaa5b 100%);
    color: #ffffff;
  }
  .item-avatar-mini {
    width: 52px;
    height: 52px;
    line-height: 49px;
    font-size: 20px;
    background-color: #ff7b00;
    color: #fff;
    text-align: center;
    border-radius: 50%;
  }
}
.noScoll{
  overflow: hidden;
}

</style>
